var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settingFormComponent"},[_c('BaseForm',{ref:"form",attrs:{"customClass":"settingCustomClass","label-width":"80px","label-position":"left","topPosition":true,"cols":_vm.formField,"form":_vm.form},scopedSlots:_vm._u([{key:"structsSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('el-button',{staticClass:"add-struct",attrs:{"size":"mini","icon":"el-icon-plus","type":"primary"},on:{"click":_vm.addNewItem}},[_vm._v(" 添加款式项 ")]),_vm._l((_vm.form[prop]),function(struct,index){return _c('div',{key:index,staticStyle:{"display":"inline-block"}},[_c('BaseForm',{ref:"structForm",refInFor:true,attrs:{"label-width":"180px","customClass":"structCommonClass","topPosition":false,"cols":_vm.structField,"form":struct || {}},scopedSlots:_vm._u([{key:"prim_colorSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('ColorSelect',{attrs:{"color":struct[prop],"sup_this":_vm.sup_this},on:{"update:color":function($event){return _vm.$set(struct, prop, $event)}}}),(_vm.form['structs'].length > 1)?_c('el-button',{staticClass:"reduce-item",attrs:{"size":"mini","icon":"el-icon-delete","type":"text"},on:{"click":function($event){return _vm.reduceNewItem(index)}}},[_vm._v(" 刪減款式项 ")]):_vm._e()]}},{key:"figuresSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('ImgUpload',{attrs:{"files":struct[prop],"multiple":"","limit":10},on:{"update:files":function($event){return _vm.$set(struct, prop, $event)}}})]}}],null,true)})],1)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }