var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basisProtoComponent",attrs:{"id":"basis"}},[(_vm.showTitle)?_c('div',{staticClass:"title"},[_vm._v("基础信息")]):_vm._e(),_c('BaseForm',{ref:"form",attrs:{"customClass":"customClass","label-width":"100px","cols":_vm.formField,"form":_vm.form},scopedSlots:_vm._u([{key:"english_nameSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('el-input',{attrs:{"placeholder":placeholder,"size":"small"},model:{value:(_vm.form[prop]),callback:function ($$v) {_vm.$set(_vm.form, prop, $$v)},expression:"form[prop]"}})]}},{key:"categorySlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('ProtoTypeTreeSelect',{staticStyle:{"width":"300px"},attrs:{"placeholder":placeholder,"selectedValue":_vm.form[prop],"maxHeight":150},on:{"update:selectedValue":function($event){return _vm.$set(_vm.form, prop, $event)},"update:selected-value":function($event){return _vm.$set(_vm.form, prop, $event)},"dataLoadEnd":_vm.dataLoadEnd}}),_c('color-text-btn',{attrs:{"type":"warning"}},[_vm._v(" 准确选择原型类目有助于完善原型信息与数据分析，原型类目及类目细项 。 ")])]}},{key:"sexSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('selectList',{attrs:{"placeholder":placeholder,"options":_vm.SEX_LIST,"keyword":_vm.form[prop]},on:{"update:keyword":function($event){return _vm.$set(_vm.form, prop, $event)}}})]}},{key:"seasonSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('selectList',{attrs:{"placeholder":placeholder,"options":_vm.SEASONS_LSIT,"keyword":_vm.form[prop]},on:{"update:keyword":function($event){return _vm.$set(_vm.form, prop, $event)}}})]}},{key:"detailSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('Editor',{attrs:{"id":"basisEditor","val":_vm.form[prop],"placeholder":placeholder,"height":300,"serverUrl":"serverUrl","useObjectStr":"CONTENT_UEDITOR_FILE"},on:{"update:val":function($event){return _vm.$set(_vm.form, prop, $event)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }